<template>
    <pageBack>
        <div class="logo"></div>
        <div class="from">
            <PaInput
                    class="myInput"
                    v-model="from.user"
                    type="email"
                    placeholder="请输入邮箱*"
                    error_content="请输入正确的邮箱"
                    :submitType="submitType"
                    :is_required="true"
                    :icon="require('@/assets/images/main/icon02.png')"
            />
            <PaInput
                    class="myInput"
                    v-model="from.password"
                    inputType="password"
                    placeholder="请输入密码*"
                    error_content="请输入密码"
                    :submitType="submitType"
                    :is_required="true"
                    :icon="require('@/assets/images/main/icon01.png')"
            />
        </div>
        <div class="submitButten" @click="submit()">登录</div>
        <div class="auxiliary">
<!--          @click="toRegister()"-->
            <div class="toRegister" @click="toRegister()">立即注册
              <el-tooltip class="item" effect="dark" content="系统升级中，敬请期待！" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
            </div>
            <div class="toReset" @click="toResetUser">忘记密码</div>
        </div>
    </pageBack>
</template>

<script>
    import pageBack from "@/components/basic/pageBack.vue";
    import PaInput from "@/components/basic/PaInput.vue";
    import {handleResponseErrors, showMsg} from "@/assets/js/utils";
    import {login} from "@/assets/js/api";
    import { mapState, mapMutations } from "vuex";

    export default {
        data() {
            return {
                from: {
                    user: {value: "", isError: false},
                    password: {value: "", isError: false}
                },
                submitType: false
            };
        },
        created(){
            window.gtag("event", "create-login", {
                event_category: "login",
                event_label: "login"
            });
        },
        components: {PaInput, pageBack},
        computed: {...mapState(["userMsg"])},
        methods: {
            ...mapMutations(["upDataUserMsg"]),
            toRegister() {
                this.$router.push({
                    path: "/register"
                });
            },
            toResetUser() {
                this.$router.push({
                    path: "/resetUser"
                });
            },
            submit() {
                window.gtag("event", "Click-login-button", {
                    event_category: "login",
                    event_label: "login"
                });
                this.submitType = true;
                this.$nextTick(async () => {
                    if (
                        Object.values(this.from).every(item => {
                            return item.isError != true;
                        })
                    ) {
                        try {
                            let data = {
                                email: this.from.user.value,
                                password: this.from.password.value
                            };
                            let res = await login.login(data);
                            if (res.code != 0) {
                                handleResponseErrors(res.code, res.msg);
                            } else {
                                showMsg("登录成功");
                                this.upDataUserMsg({
                                    createTime: res.create_time
                                });
                                localStorage.setItem("token", res.token);
                                window.gtag("event", "login-success", {
                                    event_category: "login",
                                    event_label: "login"
                                });
                                this.$router.push({
                                    path: "/storeManagement/home"
                                });
                            }
                            let sr = await login.test();
                            console.log(sr);
                        } catch (error) {
                            console.log(error);
                        }
                    }
                });
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "@/assets/css/common.scss";

    .logo {
        width: 166px;
        height: 121px;
        margin: 90px auto 0;
        background-image: url("../../assets/images/main/logo.png");
        background-size: 100% 100%;
    }

    .from {
        width: 570px;
        margin: 0 auto;
        padding: 40px 0 0;
        box-sizing: border-box;
        .myInput {
            background: #ffffff;
            margin-top: 20px;
        }
    }

    .submitButten {
        margin: 30px auto 0;
        width: 570px;
        height: 56px;
        background: $green;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
        text-align: center;
        cursor: pointer;
    }

    .auxiliary {
        width: 570px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        font-size: 18px;
        font-weight: 400;
        padding-bottom: 90px;
        .toRegister {
            cursor: pointer;
            color: $green;
        }
        .toReset {
            cursor: pointer;
            color: $fontSize-gray;
        }
    }
</style>
